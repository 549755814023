<template>
  <div class="cmp-product-price" v-if="showPrices" :class="{ 'incl-excl': priceType, [parentClassRef]: parentClassRef  }">
    <!-- BESPOKE VILTON: Show a price after calculation label instead of prices if the product price is 100000 or 121000 -->    
    <template v-if="showPriceAfterCalculation">
      <span class="price-on-calc-label" v-translation="{ type: 'label', code: 'label_to_be_calculated' }"></span>
    </template>
    <template v-else>
      <ul class="price-list flex-row-nowrap align-items-baseline">
        <li class="default">
          <span>{{ prices.price }}</span>
        </li>
        <li v-if="prices.isSalesAction" class="original salesaction">
          <span>{{ prices.basePrice }}</span>
        </li>
        <li v-if="showExclVat" class="vat-notation">
          <span v-translation="{ type: 'label', code: 'label_price1_excl_vat' }"></span>
        </li>
      </ul>
      <ul v-if="showExclVat" class="price-list flex-row-nowrap align-items-baseline price-incl">
        <li class="default">
          <span>{{ prices.priceIncl }}</span>
        </li>
        <li v-if="prices.isSalesAction" class="original salesaction">
          <span>{{ prices.basePriceIncl }}</span>
        </li>
        <li class="vat-notation"><span v-translation="{ type: 'label', code: 'label_price1_incl_vat' }"></span></li>
      </ul>
    </template>
    <!-- END BESPOKE VILTON -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  props: {
    prices: { type: Object, required: false },
    parentClassRef: { type: String, default: '', required: false }
  },
  computed: {
    ...mapGetters(['showPrices', 'priceType']),
    showExclVat() {
      return this.priceType === 3;
    },
    // BESPOKE VILTON
    showPriceAfterCalculation () {
      if (this.prices.rawPrice === 100000 || this.prices.rawPrice === 121000) {
        return true;
      } else {
        return false;
      }
    }
    // END BESPOKE VILTON
  }
}
</script>

<style>

</style>
