<template>
  <div class="cmp-product-group-matrix" v-if="render">
      <div class="matrix">
          <div class="matrix-header">
              <!-- BESPOKE VILTON -->
              <div class="prod-img"></div>
              <!-- END BESPOKE VILTON -->

              <div class="description">
                <span v-translation="{ type: 'label', code: 'label_matrix_header_product' }"></span>
              </div>

              <div class="properties" v-for="property in properties" v-bind:key="property">
                  {{ property }}
              </div>

              <div v-if="showPrices" class="prod-price">
                <span v-translation="{ type: 'label', code: 'label_matrix_header_price' }"></span>
              </div>

              <div v-if="showOrderProduct" class="prod-stock">
                <!-- BESPOKE VILTON: Changed header prod stock to unit per product header -->
                <span v-translation="{ type: 'label', code: 'label_unit_per_prod' }"></span>
                <!-- END BESPOKE VILTON -->
              </div>
              <div class="order"></div>
          </div>
          <div class="matrix-row" v-for="variant in variants" v-bind:key="variant.id">

              <!-- BESPOKE VILTON: Added product image -->
              <div class="prod-img">
                <img class="img-fluid" :alt="variant.imageAltText" :title="variant.imageTitleText" :src="variant.imageUrl ? variant.imageUrl : '/documents/productimages/not-available-small.jpg'" />
              </div>
              <!-- END BESPOKE VILTON -->

              <div class="description">
                <span class="prod-code">{{ variant.id }}</span>
                <span class="prod-desc" v-html="variant.shortDescription"></span>
              </div>

              <div class="properties" v-for="property in properties" v-bind:key="property">
                  {{variant.properties[property]}}
              </div>

              <div class="prod-price">
                <div class="ph-product-price" v-if="variant.prices.length">
                  <utlz-product-price
                    :prices="variant.prices[0]"
                  ></utlz-product-price>
                </div>
              </div>

              <div class="prod-stock" v-if="showOrderProduct">
                <!-- BESPOKE VILTON: Replaced product stock with product unit -->
                <!-- <utlz-product-stock
                  :stock="variant.stock"
                  :stockTotal="variant.stock"
                ></utlz-product-stock> -->
                <span v-html="variant.customStrings.UNIT"></span>
                <!-- END BESPOKE VILTON -->
              </div>

              <div class="order">
                <utlz-order-product
                  v-if="showOrderProduct"
                  :product="variant"
                  :allowZeroQuantity="true"
                  :useOrderMode="false"
                  @quantityChanged="quantityChanged($event, variant)"
                  @unitsChanged="unitsChanged($event, variant)"
                ></utlz-order-product>
              </div>

          </div>
      </div>
      <div v-if="showOrderProduct" class="order-product-btn flex-row-nowrap justify-content-end">
        <a
          @click="addOrderLinesToCart()"
          class="button large theme-primary"
          :class="{ 'disabled': !orderLines.length }">
          <span class="material-icons-outlined cart-icon">shopping_cart</span>
          <span v-translation="{ type: 'button', code: 'button_order' }"></span>
          </a>
      </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import OrderProduct from 'src/components/webshop/order-product/OrderProduct.vue';
import ProductPrice from 'src/components/webshop/product-price/ProductPrice.vue';
import ProductStock from 'src/components/webshop/product-stock/ProductStock.vue';
export default {
  components: {
    'utlz-order-product': OrderProduct,
    'utlz-product-price': ProductPrice,
    'utlz-product-stock': ProductStock
  },
  props: {
    variants: { type: Array, required: true }
  },
  data () {
    return {
      orderLines: [],
      render: true
    }
  },
  computed: {
    ...mapGetters(['showStock', 'userLoggedOn', 'showOrderProduct', 'showPrices']),
    properties () {
      if (this.variants.length > 0) {
        return Object.keys(this.variants[0].properties);
      }
      return [];
    }
  },
  methods: {
    // BESPOKE
    getProductDescription(value) {
      return value.replaceAll(/\u00ae/g, "<sup>&reg;</sup>").replaceAll("m2", "m<sup>2</sup>");
    },
    // END BESPOKE
    quantityChanged ({ computedQuantity, unitCode }, variant) {
      const index = this.orderLines.findIndex(orderLine => orderLine.prod_code === variant.id);
      if (computedQuantity !== 0) {
        if (index === -1) {
          this.orderLines.push({
            prod_code: variant.id,
            ord_qty: computedQuantity,
            unit_code: unitCode,
            prod_comment: ''
          });
        } else if (index > -1) {
          this.orderLines[index] = {
            prod_code: variant.id,
            ord_qty: computedQuantity,
            unit_code: unitCode,
            prod_comment: ''
          };
        }
      } else if (computedQuantity === 0) {
        this.orderLines.splice(index, 1);
      }
    },
    unitsChanged ({ unitCode, quantity, computedQuantity }, variant) {
      // Changing units resets quantity buttons to zero, so delete variant from orderLines
      const index = this.orderLines.findIndex(orderLine => orderLine.prod_code === variant.id);
      if (index > -1) {
        this.orderLines.splice(index, 1);
      }
    },
    addOrderLinesToCart () {
      this.addToCart(this.orderLines)
        .then(res => {
          this.render = false;
          this.$nextTick(() => {
            this.render = true;
            this.orderLines = [];
          });
        });
    },
    ...mapActions('shoppingCart', ['addToCart'])
  }
}
</script>

<style>

</style>
