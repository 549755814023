<template>
  <div class="cmp-shopping-cart-overview utlz-reset utlz-row" :class="tableClasses">

    <transition name="fadein">
      <utlz-prompt
        v-if="showDeleteProductPrompt"
        :payload="promptPayload.lineId"
        @confirm="deleteOrderLineFromCart($event)"
        @cancel="cancelPrompt()"
      >

      <template v-slot:prompt-content>
        <div class="delete-product-prompt utlz-row">
          <div class="utlz-col-12">
            <h2>
              <span v-translation="{ type: 'title', code: 'title_prompt_delete_from_cart' }"></span>
            </h2>
          </div>
          <div class="utlz-col-3 img">
            <img :src="promptPayload.product.imageUrl">
          </div>
          <div class="utlz-col-9">
            <h3>
              {{ promptPayload.product.shortDescription }}
            </h3>
          </div>
        </div>
      </template>

      </utlz-prompt>
    </transition>

    <transition name="fadein">
      <utlz-prompt
        v-if="showEmptyCartPrompt"
        @confirm="emptyCart()"
        @cancel="cancelEmptyCartPrompt()"
      >

      <template v-slot:prompt-content>
        <h2 v-translation="{ type: 'text', code: 'txt_confirm_clear_shopping' }"></h2>
      </template>

      </utlz-prompt>
    </transition>

    <transition name="fadein">
      <utlz-static-modal v-if="validatingStock">
        <template v-slot:static-modal-content>
          <div class="validate-stock-modal utlz-row">
            <div class="utlz-col-12 flex-row-nowrap align-center just-center">
              <span class="loader"></span>
              <h2>
                <span v-translation="{ type: 'label', code: 'label_validating_stock' }"></span>
              </h2>
            </div>
          </div>
        </template>
      </utlz-static-modal>
    </transition>

    <transition-group name="fade" tag="div" class="ph-wrapper utlz-col-12">
      <template v-if="loadingOrderLines">
        <div v-for="index in 4" :key="index" class="ph-silhouette orderlist-row">
          <span class="silhouette-img"></span>
          <div class="ph-paragraph">
            <span class="silhouette-line"></span>
            <span class="silhouette-line"></span>
          </div>
        </div>
      </template>
    </transition-group>

    <div v-if="shoppingCartEmpty" class="empty-cart-message col-md-12">
      <h2 v-translation="{ type: 'title', code: 'title_shopping_cart_empty' }"></h2>
    </div>

    <transition name="fade">
      <div v-if="showOrderLines && screenWidth >= 880" class="orderline-header utlz-col-md-12">
        <div class="orderlines-wrapper">
          <div v-if="!hideShoppingCartImages" class="prod-img orderline-cell"></div>
          <div class="prod-desc orderline-cell">
            <span v-translation="{ type: 'label', code: 'label_header_prod_desc' }"></span>
          </div>
          <div v-if="showPrices" class="prod-price orderline-cell">
            <span v-translation="{ type: 'label', code: 'label_header_prod_price' }"></span>
          </div>

          <div class="prod-stock orderline-cell">
            <!-- BESPOKE VILTON: Replaced stock cell with prod unit cell -->
            <span v-translation="{ type: 'label', code: 'label_unit_per_prod' }"></span>
            <!-- END BESPOKE VILTON -->
          </div>

          <div class="order-product orderline-cell">
            <span v-translation="{ type: 'label', code: 'label_header_prod_quantity' }"></span>
          </div>
          <div v-if="showPrices" class="prod-total orderline-cell">
            <span v-translation="{ type: 'label', code: 'label_header_prod_total' }"></span>
          </div>
          <div class="delete-prod orderline-cell">
          </div>
        </div>
      </div>
    </transition>

    <transition-group name="fade" tag="div" class="orderline-table utlz-col-md-12">
      <template v-if="showOrderLines">
        <div v-for="orderLine in orderLines" :key="orderLine.lineId" class="orderline-row">

          <div v-if="!hideShoppingCartImages" class="prod-img orderline-cell">
            <a :href="orderLine.product.targetUrl">
              <img :src="orderLine.product.imageUrl" />
            </a>
          </div>

          <div class="prod-desc orderline-cell">
            <a :href="orderLine.product.targetUrl">
              <!-- BESPOKE VLINT: Render text as html instead as plain text so we can show copyrigt and m2 in superscript -->
              <h3 class="prod-title body-text" v-html="orderLine.product.shortDescription"></h3>
              <!-- END BESPOKE VLINT -->
              <span class="prod-code">{{ orderLine.product.id }}</span>
            </a>

            <template v-if="useOrderLineComments && screenWidth >= 880">
              <utlz-order-line-comment
                :orderLine="orderLine"
                ></utlz-order-line-comment>
            </template>
          </div>

          <template v-if="showPrices">
            <div class="prod-price orderline-cell" v-if="orderLine.product.prices.length">
              <utlz-product-price
                :prices="orderLine.product.prices[0]"
              ></utlz-product-price>
            </div>
            <div v-else class="stock-status-wrapper text-placeholder pulse">
              <span class="loader"></span>
            </div>
          </template>

          <div class="prod-stock orderline-cell">
            <!-- BESPOKE VILTON: Replaced stock with product unit -->
            <!-- <utlz-product-stock
              :stock="orderLine.product.stock"
              :stockTotal="orderLine.product.stock"
            ></utlz-product-stock> -->
            <span v-html="orderLine.product.customStrings.UNIT"></span>
            <!-- END BESPOKE VILTON -->
          </div>

          <div class="order-product orderline-cell">
            <span v-if="loading" class="loader"></span>
            <utlz-order-product
              v-if="orderLine.product.stock !== null"
              :product="orderLine.product"
              :initialQuantity="orderLine.quantity"
              :initialUnitCode="orderLine.unitCode"
              :useOrderMode="false"
              :totalOrderLinesQuantity="orderLine.totalOrderLinesQuantity"
              @quantityChanged="quantityChanged($event, orderLine)"
              @unitsChanged="unitsChanged($event, orderLine)"
            ></utlz-order-product>
          </div>

          <!-- BESPOKE VILTON: Show a label instead of the total price if the products price is 100000 or 121000 -->
          <div class="prod-total orderline-cell">
            <template v-if="orderLine.product.prices.length">
              <template v-if="orderLine.product.prices[0].rawPrice !== 100000 && orderLine.product.prices[0].rawPrice !== 121000">
                <span>{{ orderLine.totals.totalPrice }}</span>
              </template>
              <template v-else>
                <span class="price-on-calc-label" v-translation="{ type: 'label', code: 'label_to_be_calculated' }"></span>
              </template>
            </template>
          </div>
          <!-- END BESPOKE VILTON -->

          <div class="delete-prod orderline-cell">
            <span class="close-button small grey" @click="promptDelete(orderLine)">
              <i class="fa fa-times-circle" aria-hidden="true"></i>
            </span>
          </div>

          <template v-if="useOrderLineComments && screenWidth < 880">
            <div class="mobile-order-comment">
              <utlz-order-line-comment
                :orderLine="orderLine"
              ></utlz-order-line-comment>
            </div>
          </template>

        </div>
      </template>

    </transition-group>

    <template v-if="showEmptyShoppingCart && !shoppingCartEmpty && !loadingOrderLines">
      <div class="utlz-col-md-12 clear-cart-col">
        <a @click="showEmptyCartPrompt = true" v-translation="{ type: 'button', code: 'btn_clear_shopping_cart' }" class="button small outline clear-cart"></a>
      </div>
    </template>

    <div id="stock-warning" class="utlz-col-md-6">
      <transition name="fade">
        <template v-if="invalidOrderLines.length">
          <div class="stock-warning">
            <div class="stock-warning-wrapper">
              <h2 v-translation="{ type: 'title', code: 'title_shopping_cart_stock_warning' }"></h2>
              <ul>
                <li v-for="orderLine in invalidOrderLines" :key="orderLine.lineId">
                  <i class="fa fa-caret-right" aria-hidden="true"></i><span>{{ orderLine.product.shortDescription }}</span>
                </li>
              </ul>
            </div>
          </div>
        </template>
      </transition>

      <utlz-coupons v-if="useCoupons && showOrderLines"></utlz-coupons>

    </div>

    <div class="utlz-col-md-6">
      <transition name="fade">
        <utlz-shopping-cart-totals
          v-if="showOrderLines"
          :format="shoppingCartTotalsFormat"
        ></utlz-shopping-cart-totals>
      </transition>
    </div>

  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Prompt from 'src/components/ui/prompt/Prompt.vue';
import StaticModal from 'src/components/ui/static-modal/StaticModal.vue';
import ProductPrice from 'src/components/webshop/product-price/ProductPrice.vue';
import ProductStock from 'src/components/webshop/product-stock/ProductStock.vue';
import OrderProduct from 'src/components/webshop/order-product/OrderProduct.vue';
import Coupons from 'src/components/webshop/coupons/Coupons.vue';
import OrderLineComment from 'src/components/webshop/order-product/blocks/OrderLineComment.vue';
import ShoppingCartTotals from 'src/components/webshop/shopping-cart/ShoppingCartTotals.vue';
import _ from 'lodash';

export default {
  components: {
    'utlz-prompt': Prompt,
    'utlz-static-modal': StaticModal,
    'utlz-product-price': ProductPrice,
    'utlz-product-stock': ProductStock,
    'utlz-order-product': OrderProduct,
    'utlz-coupons': Coupons,
    'utlz-order-line-comment': OrderLineComment,
    'utlz-shopping-cart-totals': ShoppingCartTotals
  },
  data () {
    return {
      loading: false,
      showDeleteProductPrompt: false,
      promptPayload: null,
      showEmptyCartPrompt: false
    }
  },
  computed: {
    ...mapGetters(['screenWidth', 'showStock', 'useCoupons', 'useOrderLineComments', 'useMinimumOrderQuantity', 'hideShoppingCartImages', 'showEmptyShoppingCart', 'showPrices']),
    ...mapGetters('shoppingCart', ['orderLines', 'invalidOrderLines', 'loadingOrderLines', 'orderLinesTotal', 'minimumOrderQuantity', 'shoppingCartEmpty', 'shoppingCartTotalsFormat', 'validatingStock']),
    showOrderLines () {
      return this.orderLines.length > 0 && this.orderLines[0].product !== null;
    },
    tableClasses () {
      let classes = '';
      if (!this.showStock) {
        classes += 'hide-stock ';
      }
      if (this.hideShoppingCartImages) {
        classes += 'hide-images ';
      }
      return classes;
    }
  },
  methods: {
    ...mapActions('shoppingCart', ['initShoppingCart', 'updateOrderLineQuantity', 'deleteOrderLine', 'emptyShoppingCart']),
    quantityChanged ({ computedQuantity }, orderLine) {
      const payload = {
        Comments: orderLine.comments,
        ProductId: orderLine.productId,
        LineId: orderLine.lineId,
        Quantity: computedQuantity.toString(),
        UnitCode: orderLine.unitCode,
        IsFree: orderLine.isFree
      };
      this.updateOrderLineQuantity(payload).then(res => {
      });
    },
    unitsChanged ({ unitCode, quantity, computedQuantity }, orderLine) {
      const payload = {
        Comments: orderLine.comments,
        ProductId: orderLine.productId,
        LineId: orderLine.lineId,
        Quantity: computedQuantity.toString(),
        UnitCode: unitCode,
        IsFree: orderLine.isFree
      };
      this.updateOrderLineQuantity(payload).then(res => {
      });
    },
    promptDelete (orderLine) {
      this.showDeleteProductPrompt = true;
      this.promptPayload = orderLine;
    },
    cancelPrompt () {
      this.showDeleteProductPrompt = false;
      this.promptPayload = null;
    },
    deleteOrderLineFromCart (orderLineId) {
      this.deleteOrderLine(orderLineId);
      this.showDeleteProductPrompt = false;
      this.promptPayload = null;
    },
    cancelEmptyCartPrompt () {
      this.showEmptyCartPrompt = false;
    },
    emptyCart () {
      this.showEmptyCartPrompt = false;
      this.emptyShoppingCart();
    }
  },
  created () {
    this.initShoppingCart();
    this.quantityChanged = _.debounce(this.quantityChanged, 500);
  }
}
</script>

<style>

</style>
