import Vue from 'vue';
import ElasticProductList from 'src/components/webshop/elastic/elastic-product-list/ElasticProductList.vue';
import ElasticProductDetails from 'src/components/webshop/elastic/elastic-product-details/ElasticProductDetails.vue';
import ProductBlock from 'src/components/webshop/product-block/ProductBlock.vue';
import ElasticProductFilters from 'src/components/webshop/elastic/elastic-product-filters/ElasticProductFilters.vue';
import ShoppingCartControl from 'src/components/webshop/shopping-cart/ShoppingCartControl.vue';
import ShoppingCartOverview from 'src/components/webshop/shopping-cart/ShoppingCartOverview.vue';
import QuickOrder from 'src/components/webshop/quick-order/QuickOrder.vue';
import store from 'src/store/store';
// Directives
import TranslationDirective from 'src/directives/TranslationDirective';
import FormatPriceDirective from 'src/directives/FormatPriceDirective';
import OutsideClickDirective from 'src/directives/OutsideClickDirective';
// Setup global directives
Vue.directive('translation', TranslationDirective);
Vue.directive('format-price', FormatPriceDirective);
Vue.directive('outside-click', OutsideClickDirective);

// Initializes shoppingcart control
window.loadVuexShoppingCartControl = function () {
  const shoppingCartControl = window.vue.modules.shoppingCartControl;
  if (shoppingCartControl) {
    const loadVuexShoppingCartControl = new Vue({
      store: store,
      created () {
        this.$store.commit('shoppingCart/initConfig', shoppingCartControl.config.config);
      },
      render: h => h(ShoppingCartControl)
    }).$mount('#shopping-cart-control');
  };
}

window.loadVuexShoppingCartOverview = function () {
  // Initializes shoppingcart overview
  const shoppingCartOverview = window.vue.modules.shoppingCartOverview;
  if (shoppingCartOverview) {
    const loadVuexShoppingCartOverview = new Vue({
      store: store,
      render: h => h(ShoppingCartOverview)
    }).$mount('#shopping-cart-overview');
  };
}

$(window).load(() => {
  // Initializes the store with global settings from uc_vue_data_transfer
  window.vuexStore = new Vue({
    store: store,
    created () {
      this.$store.dispatch('initBrowserDetection');
      this.$store.dispatch('setGlobalCmsSettings', window.vue.globalData.cmsSettings);
      this.$store.dispatch('setGlobalWsSettings', window.vue.globalData.webshopSettings);
      this.$store.dispatch('setGlobalUserInformation', window.vue.globalData.userInformation);
      this.$store.dispatch('setGlobalEndpoints', window.vue.globalData.endpoints);
    }
  });

  // Initializes quick order
  const quickOrder = window.vue.modules.quickOrder;
  if (quickOrder) {
    new Vue({
      store: store,
      created () {
        
      },
      render: h => h(QuickOrder)
    }).$mount('#quick-order');
  }

  // Initializes elastic search module
  const elasticSearch = window.vue.modules.elasticSearch;
  if (elasticSearch) {
    new Vue({
      store: store,
      created () {
        this.$store.commit('elastic/initConfig', elasticSearch.config.config);
        this.$store.commit('elastic/initSearchConfig', elasticSearch.config.searchConfig);
      },
      render: h => h(ElasticProductList)
    }).$mount('#elastic-search');
  }

  // Initializes elastic product details module
  const productDetails = window.vue.modules.productDetails;
  if (productDetails) {
    new Vue({
      store: store,
      created () {
        this.$store.commit('productDetails/setProductCode', productDetails.config.productCode);
        this.$store.commit('productDetails/setGroupFields', productDetails.config.groupFields);
        this.$store.commit('productDetails/setSearchConfig', productDetails.config.searchConfig);
      },
      render: h => h(ElasticProductDetails)
    }).$mount('#elastic-detail-page');
  }

  // Initializes elastic aggregations module
  const elasticAggrFilters = window.vue.modules.elasticAggrFilters;
  if (elasticAggrFilters) {
    new Vue({
      store: store,
      render: l => l(ElasticProductFilters)
    }).$mount('#elastic-aggr-filters');
  };

  loadVuexShoppingCartControl();
  loadVuexShoppingCartOverview();

  window.vue.productBlocks.forEach(config => {
    new Vue({
      store: store,
      data: {
        config: config
      },
      render: h => h(ProductBlock)
    }).$mount('#block-' + config.Target);
  });
});

Vue.config.productionTip = false
