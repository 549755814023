<template>
  <div class="cmp-elastic-product-list utlz-row utlz-reset" :class="layoutType" ref="productListContainer">
    <div class="utlz-col-md-12">
      <!-- BESPOKE VILTON: Changed title from text to v-html -->
      <h1 v-html="title"></h1>
      <!-- END BESPOKE VILTON -->

        <utlz-sorting-options
          id="sorting-options"
          v-if="!noProductsFound"
          @changeLayout="changeLayout($event)"
          @changePageSize="changePageSize($event)"
          @changePageSorting="changePageSorting($event)"
          :pageRange="pageRange"
          :totalProducts="totalProducts"
          :layoutType="layoutType"
          :parentClassRef="'ref-elastic-product-list'"
          :pageSize="pageSizes"
          :pageSorting="sortingOptions">

          <template v-slot:pagination>
            <utlz-pagination v-if="totalPages > 1" :totalPages="totalPages" :currentPage="currentPage" :scrollToTop="true" @changePage="changePage($event)"></utlz-pagination>
          </template>

          <template v-slot:toggle-mobile-filters>
            <a @click="toggleMobileFilters()" class="toggle-filters-btn button small theme-primary show-mobile">
              <i class="fa fa-filter"></i>
              <span v-translation="{ type: 'label', code: 'label_toggle_mobile_filters' }"></span>
            </a>
          </template>

        </utlz-sorting-options>

        <transition-group name="fadein" tag="div" class="list-items-wrapper utlz-row">
          <template v-if="!products.length && !noProductsFound">
            <div v-for="index in (4 * blockSize)" :key="index" class="ph-silhouette product-list-item utlz-col-12" :class="'utlz-col-sm-' + blockSize">
              <span class="silhouette-img square"></span>
              <div class="ph-paragraph">
                <span class="silhouette-line"></span>
                <span class="silhouette-line"></span>
                <span class="silhouette-line"></span>
                <span class="silhouette-line"></span>
              </div>
            </div>
          </template>
        </transition-group>

        <transition-group name="fade" tag="div" class="list-items-wrapper utlz-row">
            <utlz-product-list-item
              @navigateToProductPage="navigateToProductPage($event)"
              v-for="product in products"
              :key="product.id"
              :product="product"
              :blockSize="blockSize"
              class="product-list-item">
            </utlz-product-list-item>
          </transition-group>

        <utlz-pagination v-if="totalPages > 1" :totalPages="totalPages" :currentPage="currentPage" @changePage="changePage($event)"></utlz-pagination>

        <div v-if="noProductsFound" class="no-products-found">
          <h2 v-translation="{ type: 'label', code: 'label_no_products_found'}"></h2>
        </div>

      </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import SortingOptions from 'src/components/webshop/sorting-options/SortingOptions.vue';
import ProductListItem from 'src/components/webshop/products/product-list-item/ProductListItem.vue';
import Pagination from 'src/components/ui/pagination/Pagination.vue';
export default {
  components: {
    'utlz-sorting-options': SortingOptions,
    'utlz-pagination': Pagination,
    'utlz-product-list-item': ProductListItem
  },
  computed: {
    ...mapState('elastic', ['products', 'filters', 'stringProperties', 'layoutType', 'blockSize', 'pageSizes', 'sortingOptions']),
    ...mapGetters('elastic', ['pageTitle', 'totalPages', 'currentPage', 'pageRange', 'totalProducts', 'noProductsFound']),
    // BESPOKE
    title() {
      var title = this.pageTitle.replaceAll(/\u00ae/g, "<sup>&reg;</sup>").replaceAll("m2", "m<sup>2</sup>");
      return title;
    }
    // END BESPOKE
  },
  methods: {
    ...mapActions('elastic', ['initElastic', 'updateFilters', 'changePage', 'changeLayout', 'changePageSize', 'toggleMobileFilters', 'changePageSorting']),
    navigateToProductPage (href) {
      location.href = href;
      localStorage.setItem('scrollPositionTop', window.pageYOffset);
    }
  },
  created () {
    this.initElastic();
  },
  updated () {
    window.scrollTo(0, localStorage.getItem('scrollPositionTop'));
    localStorage.setItem('scrollPositionTop', 0);

    // BESPOKE VILTON-B2C
    if ($('#ph-header').length) {
      $('#ph-header').append($('h1'));
    }
    // END BESPOKE VILTON-B2C

  }
};
</script>

<style>
</style>
