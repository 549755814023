<template>
  <div class="cmp-product-documents utlz-row">
    <div class="utlz-col-12">
      <ul>
        <li v-for="(document, index) in documents" :key="index">
          <a class="text-link" :href="document.fileLocation" target="_blank">
            <i class="fa fa-file-text-o"></i>
            <span>{{ document.description }}</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    documents: { type: Array, required: true }
  }
}
</script>

<style>

</style>
