import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios';

// Import modules
import elastic from 'src/store/modules/elastic';
import productDetails from 'src/store/modules/product-details';
import shoppingCart from 'src/store/modules/shopping-cart';
import orderEntry from 'src/store/modules/order-entry';

// Global modules
import globalBrowser from 'src/store/modules/global/global-browser';
import globalWsSettings from 'src/store/modules/global/global-ws-settings';
import globalCmsSettings from 'src/store/modules/global/global-cms-settings';
import globalUserInformation from 'src/store/modules/global/global-user-information';
import globalEndpoints from 'src/store/modules/global/global-endpoints';

// Configure Axios defaults
axios.defaults.headers.post['Content-Type'] = 'application/json; charset=utf-8';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  getters: {
  },
  mutations: {

  },
  actions: {

  },
  modules: {
    elastic: elastic,
    shoppingCart: shoppingCart,
    productDetails: productDetails,
    orderEntry: orderEntry,
    globalBrowser: globalBrowser,
    globalEndpoints: globalEndpoints,
    globalWsSettings: globalWsSettings,
    globalCmsSettings: globalCmsSettings,
    globalUserInformation: globalUserInformation
  }
})
