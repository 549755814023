<template>
  <div class="cmp-order-line-comment">
      <div class="flex-row-nowrap" :class="{ 'editing': editing }">
        <input type="text"
          ref="orderLineComment"
          :placeholder="placeholder"
          :value="orderLine.comments"
          @focus="edit()"
          @blur="updateComment()"
          @keyup.enter="$refs.orderLineComment.blur()"
          class="form-field text-theme-primary flex-sm-grow-1"
        />
        <a v-if="!editing" class="button-pencil" @click="edit()">
          <i class="fa fa-pencil" aria-hidden="true"></i>
        </a>
        <a v-else class="button square theme-primary">
          <i class="fa fa-check" aria-hidden="true"></i>
        </a>
        <transition name="fade">
          <span v-if="loading" class="loader"></span>
        </transition>
      </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  props: {
    orderLine: { type: Object, required: true }
  },
  data () {
    return {
      loading: false,
      editing: false,
      placeholder: window.vue.translations.label.label_orderline_comment_ph
    }
  },
  methods: {
    ...mapActions('shoppingCart', ['updateOrderLineComment']),
    edit () {
      this.editing = true;
      this.$refs.orderLineComment.focus();
    },
    toggleEditing () {
      this.editing = !this.editing;
    },
    updateComment (orderLine) {
      const comment = this.$refs.orderLineComment.value;
      if (comment !== this.orderLine.comments) {
        this.loading = true;
        const payload = {
          Comments: comment,
          ProductId: this.orderLine.productId,
          LineId: this.orderLine.lineId,
          Quantity: this.orderLine.quantity,
          UnitCode: this.orderLine.unitCode,
          IsFree: this.orderLine.isFree
        };
        this.updateOrderLineComment(payload)
          .then(res => {
            this.loading = false;
          })
          .catch(
            err => {
              this.loading = false;
            });
      }
      this.$refs.orderLineComment.blur();
      this.editing = false;
    }
  }
}
</script>

<style>

</style>
